<template>
  <div>
    <base-header base-title="Entity Data Version"></base-header>
    <div class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content">
      <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__head kt-portlet__head--lg">
          <base-title :head-title="headTitle"></base-title>
        </div>
        <div class="kt-portlet__body">
          <div class="row">
            <div class="col-md-6">
              <multiselect
                v-model="entityValue"
                placeholder="All Entity"
                :options="optionsEntity"
                :custom-label="labelEntity"
                searchable
              >
                <template slot="singleLabel" slot-scope="{ option }">{{
                  option | checkData
                }}</template>
              </multiselect>
            </div>
          </div>
          <template v-if="entityValue || isCheckData">
            <div v-if="isLoaded" class="mt-2">
              <v-server-table
                :columns="columns"
                :options="options"
                ref="table"
                @loaded="onLoaded(0)"
                @loading="onLoaded(1)"
              >
                <div slot="modifiedDate" slot-scope="{ row }">
                  <div>
                    {{ row.modifiedDate | changeDate }}
                  </div>
                </div>
                <div slot="entityType" slot-scope="{ row }">
                  <div>
                    {{ row.entityType | checkData }}
                  </div>
                </div>
                <div v-if="isLoading" slot="afterBody">
                  <h6 class="txt">loading..</h6>
                </div>
                <div slot="actions" slot-scope="{ row }">
                  <button
                    class="btn btn-outline-info btn-sm"
                    @click="toRoute(row)"
                  >
                    Version
                  </button>
                </div>
              </v-server-table>
            </div>
            <div v-else class="m-auto p-5 d-flex flex-row h-100">
              <div
                class="
                  kt-spinner kt-spinner--v2 kt-spinner--lg kt-spinner--primary
                  my-auto
                "
              ></div>
              <h5 class="my-auto pl-5">Loading ...</h5>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseHeader from "./../_base/BaseHeader";
import BaseTitle from "./../_base/BaseTitle";
import Multiselect from "vue-multiselect";
import { RepositoryFactory } from "../../repositories/RepositoryFactory";
const EntityDataRepository = RepositoryFactory.get("entityVersionRepository");
import axios from 'axios'
export default {
  components: {
    BaseHeader,
    BaseTitle,
    Multiselect,
  },
  watch: {
    entityValue(val) {
      this.isLoaded = false;
      if (val) {
        localStorage.setItem("entityType", val);
        this.$router.replace({
          name: "entity-data-version",
          query: {
            type: val,
          },
        });
      } else {
        this.$router.replace({
          name: "entity-data-version",
        });
        localStorage.removeItem("entityType");
      }
      setTimeout(() => {
        this.isLoaded = true;
      }, 1000);
    },
  },
  data() {
    var vx = this;
    return {
      cancelToken: null,
      isCheckData: false,
      headTitle: "Entity Data Version",
      entityValue: "all",
      optionsEntity: [],
      isLoaded: false,
      isLoading: false,
      columns: [
        "id",
        "entityId",
        "entityType",
        "modifiedDate",
        "modifiedBy",
        "commitVersion",
        "action",
        "actions",
      ],
      options: {
        headings: {
          id: "ID",
          entityId: "Entity Id",
          entityType: "Entity Type",
          modifiedDate: "Modified Date",
          modifiedBy: "Modified By",
          commitVersion: "Versi",
          action: "Type",
          actions: "Action",
        },
        sortIcon: {
            base: "fa",
            is: "fa-sort",
            up: "fa-sort-up",
            down: "fa-sort-down "
        },
        sortable: ["modifiedDate", "commitVersion"],
        filterable: ["entityId"],
        debounce: 500,
        requestFunction(data) {
          let params = {};
          let page = {};
          page.page = data.page - 1;
          page.size = data.limit;
          page.sort = "id";
          this.isLoaded = false;
          if (localStorage.entityType) {
            page.entityType = localStorage.entityType;
          }
          if (_.isEmpty(data.query) && localStorage.login) {
            data.query = { login: localStorage.login };
            // setTimes
          } else {
            localStorage.removeItem("login");
          }
          if (data.query.entityId) {
            page.entityId = data.query.entityId;
          }
          if (data.orderBy == "modifiedDate") {
            page.sort = "modifiedDate";
          } else if (data.orderBy == "commitVersion") {
            page.sort = "commitVersion";
          }
          if (data.ascending == "1") {
            page.sort += ",desc";
          } else {
            page.sort += ",asc";
          }
          if(vx.axiosSource && vx.axiosSource.cancel) {
              vx.axiosSource.cancel()
          }
          const axiosSources = axios.CancelToken.source();
          vx.axiosSource = { cancel: axiosSources.cancel };
          return EntityDataRepository.fetch(page, {cancelToken: axiosSources.token}).catch(function (e) {
           console.log('error')
          });
        },
        responseAdapter: (resp) => {
          return {
            data: resp.data,
            count: resp.headers["x-total-count"]
              ? resp.headers["x-total-count"]
              : resp.headers["Content-Length"]
              ? resp.headers["Content-Length"]
              : resp.data.length,
          };
        },
        filterByColumn: true,
      },
    };
  },
  filters: {
    checkData(item) {
      const sliceData = item.split(".");
      if(sliceData[5]) return sliceData[5]
      return item
    },
    changeDate(date) {
      return moment(date).format("dddd, DD MMMM YYYY , HH:mm");
    },
  },
  mounted() {
    setTimeout(() => {
      if(!this.$route.query.type){
        this.entityValue = "";
      }
      this.isCheckData = true;
      this.getAllEntity();
    }, 500);
  },
  methods: {
    toRoute(item) {
      // if(item.commitVersion > 1) {
      this.$router.push({
        name: "version-entity",
        params: {
          id: item.entityId,
          version: item.commitVersion,
        },
        query: {
          type: item.entityType,
        },
      });
      // } else {
      //   toastr.info('Versi paling Baru')
      // }
    },
    async getAllEntity() {
      let response = await EntityDataRepository.getAllAuditEntity();
      if (response.status == 200) {
        this.optionsEntity = response.data;
        if (this.$route.query.type) {
          this.entityValue = this.$route.query.type;
        }
      }
    },
    labelEntity(item) {
      const sliceData = item.split(".");
      if(sliceData[5]) return sliceData[5]
      return item
    },
    onLoaded: function (t) {
      t == 0 ? (this.isLoading = false) : (this.isLoading = true);
    },
  },
};
</script>
